import React from "react";
import { Grid, Box, Typography, Divider, Button, Link} from "@mui/material";
import Title from "../../components/Utilities/Title";
import { useLoaderData } from "react-router-dom";

export default function PageNotFound() {
    return (
    <>
    <Box sx={{ width: {xs: "85%", md: "75%"}, margin: "7.5% auto" }}>
    <Grid 
         container 
         direction="row" 
         spacing={3} 
         alignContent="center"
         justifyContent="center"
      >
    <Grid item xs={12}>
        <Box
        sx={{
            width: {xs: "100%", md: "60%"},
            margin: "0 auto"
        }}
        >
        <Title text="That page can't be found" color="secondary"  />
        </Box>
    </Grid>
    <Divider />
    <Grid item xs={12}>
    <Box
        sx={{
            width: {xs: "100%", md: "60%"},
            margin: "0 auto"
        }}
        >
    <Typography variant="body1">We can't find the page you're looking for.</Typography>
    <Typography variant="body1">If you typed the URL, double-check that it was typed correctly.</Typography>
    </Box>
    </Grid>
    <Divider />
    <Grid item xs={12}>
    <Box
        sx={{
            width: {xs: "100%", md: "60%"},
            margin: "0 auto"
        }}
        >
        <Button variant="filledGreen" href="/">Return to Home</Button>
        </Box>
    </Grid>
    </Grid>
    </Box>
    </>
    )   
   }