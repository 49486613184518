import React from 'react';
import { Box, Button, Container, Divider, Grid, Icon, Typography } from "@mui/material";

// This imports the functional component from the previous sample.
import {default as VideoJS} from './Videojs'

export default function VideoPlayerBox(props) {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    preload: 'auto',
    responsive: true,
    poster: props.thumbnail,
    fluid: true,
    sources: [{
      src: props.source,
      type: 'video/mp4'
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  return (
    <>
  
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady}/>

    </>
  );
}