import React from 'react';
import { Grid, Button, Typography, Box} from "@mui/material";
import { CartContext } from "./src/CartContext";
import { useContext } from "react";
import { getProductData } from "./src/productsStore";
import CancelIcon from '@mui/icons-material/Cancel';

function CartProduct(props) {
    const cart = useContext(CartContext);
    const id = props.id;
    const quantity = props.quantity;
    const productData = getProductData(id);
    return (
        <>
        <Grid container direction="row" spacing={1} py={1} justifyContent={'flex-start'}>
            <Grid item xs={12} sm={6}>
            <Box sx={{display: 'flex', width: "100%", height: "100%", alignItems: 'center'}}>
            <Typography><strong>{productData.name}</strong></Typography>
            </Box>
            </Grid>
            <Grid item xs={3} sm={2}>
            <Box sx={{display: 'flex', width: "100%", height: "100%", alignItems: 'center'}}>
            <Typography>Qty:  &nbsp; {quantity}</Typography>
            </Box>
            </Grid>
            <Grid item xs={3} sm={1}>
            <Box sx={{display: 'flex', width: "100%", height: "100%", alignItems: 'center'}}>
            <Typography><strong>${ (quantity * (productData.product_price.unit_amount/100)).toFixed(2) }</strong></Typography>
            </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
            <Button variant="cartInsideRemove" onClick={() => cart.deleteFromCart(id)}>Remove<span id="removeIcon"><CancelIcon /></span></Button>
            </Grid>
        </Grid>
        </>
    )
}

export default CartProduct;