import React from "react";
import Title from "../../components/Utilities/Title";
import { Grid, Box, Typography, Divider } from "@mui/material";
import FlowerRow from "../../components/Images/FlowerRow";
// import { useLoaderData } from "react-router-dom";

export default function SubSuccess() {

    return (
        <>
            <Box sx={{width: "75%", margin: "0 auto"}}>
            <Title text="We're so glad you're here!" />
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="center"
                mt={2}
                p={1}
                spacing={4}
                sx={{width: "50%", margin: "0 auto", border: "solid 1px rgba(0,0,0,0.2)"}}
            >
            <Grid item>
                <FlowerRow />
            </Grid>
            <Grid item xs={12} p={2}>
                <Typography variant="body1" textAlign="center">
                By your request, <strong>your subscription has now been activated.</strong> You should receive a confirmation email momentarily.
                </Typography>

            </Grid>
            <Grid item xs={12} p={2}>

                <Typography variant="body1" textAlign="center">
                Winter Wisdoms is only possible because of subscribers like you - and for that, you have our gratitude. 
                </Typography>
            </Grid>

            </Grid>
            </Box>
        </>
    )
}