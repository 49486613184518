import React from "react";
import { Grid, Typography, Divider } from "@mui/material";

export default function Title(props) {
    return (<>
        <Grid container
            direction="row"
            justifyContent="center"
            alignItems="center"

            mt={4}
            mb={4}
        >
            {props.dividerTop && 
                <Grid item xs={12} color={props.color}>
                    <Divider />
                </Grid>
            }
            <Grid item xs={12}>
                <br />
                <Typography color={props.color} variant="h2" textAlign={props.textAlign !== undefined ? props.textAlign : "center"}>
                    {props.text}
                </Typography>
                <br />
            </Grid>
            {!props.dividerTop &&
                <Grid item xs={12} color={props.color}>
                    <Divider />
                </Grid>
            }
        </Grid>
    </>)
}