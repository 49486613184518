import React from "react";
import { default as PageDetailLoaderRef} from "../loaders/PageDetailLoader";
import { default as PageLoaderRef} from "../loaders/PageLoader";
import { default as UserLoaderRef} from "../loaders/UserLoader";
import { default as BillingPortalLoaderRef} from "../loaders/stripe/BillingPortalLoader";
import { default as CheckoutSessionLoaderRef} from "../loaders/stripe/CheckoutSessionLoader";
import { default as CustomerLoaderRef} from "../loaders/stripe/CustomerLoader";
import { default as PricesLoaderRef} from "../loaders/stripe/PricesLoader";
import { default as ProductsLoaderRef} from "../loaders/stripe/ProductsLoader";
import { default as SubscriptionsLoaderRef} from "../loaders/stripe/SubscriptionsLoader";
import {default as ServiceSelectionLoaderRef} from "../loaders/ServiceSelectionLoader";
import { default as VideoSelectionLoaderRef} from "../loaders/VideoSelectionLoader";
import { default as VideoLoaderRef } from "../loaders/VideoLoader";
import { default as HomeVideoRef } from "../loaders/HomeLoader";

export const PageDetailLoader = PageDetailLoaderRef;
export const PageLoader = PageLoaderRef;
export const UserLoader = UserLoaderRef;
export const BillingPortalLoader = BillingPortalLoaderRef;
export const CheckoutSessionLoader = CheckoutSessionLoaderRef;
export const CustomerLoader = CustomerLoaderRef;
export const PricesLoader = PricesLoaderRef;
export const ProductsLoader = ProductsLoaderRef;
export const SubscriptionsLoader = SubscriptionsLoaderRef;
export const ServiceSelectionLoader = ServiceSelectionLoaderRef;
export const VideoSelectionLoader = VideoSelectionLoaderRef;
export const VideoLoader = VideoLoaderRef;
export const HomeLoader = HomeVideoRef;
