import React from "react";
import { createTheme } from "@mui/material";

// Breakpoints!
// xs, extra-small: 0px or larger
// sm, small: 600px or larger
// md, medium: 960px or larger
// lg, large: 1280px or larger
// xl, extra-large: 1920px or larger

export const Theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#0e5352",
      light: "#267976",
      // main: '#267976',
      // light: '#4b7f7e',
    },
    secondary: {
      main: "#874973",
      dark: "#6c2c58",
    },
    beige: {
      main: "#D3B8AA",
    },
    warning: {
      main: "#c28a78",
      dark: "#b36d56",
      contrastText: "#3f3f3f",
    },
    info: {
      main: "#0e5352",
    },
    success: {
      main: "#9e87a7",
    },
    text: {
      primary: "#3f3f3f",
      secondary: "#505050",
      disabled: "#777777",
      hint: "#3f3f3f",
    },
    background: {
      default: "#f9f8f4",
      paper: "#f9f8f4",
    },
    error: {
      main: "#D48440",
      light: "#d89053",
      dark: "#CF762B",
      contrastText: "#3f3f3f",
    },
  },

  // Open Sans supports weights between 300 and 800
  typography: {
    fontFamily: "Open Sans, sans-serif",
    button: {
      fontWeight: 500,
      lineHeight: 2.6,
    },

    h1: {
      fontFamily: "Italiana, sans-serif",
      fontsize: "2.5rem",
      color: "primary.main",
      letterSpacing: "1px",
    },
    // h2 is used for the Title component for Page titles

    h2: {
      fontFamily: "Italiana, sans-serif",
      fontSize: "3.3rem",
      letterSpacing: "1px",
      color: "#0e5352", // primary.main
    },
    // h3 is used for subtitles - 1 sentence paragraphs underneath page titles. E.G. The All Services page
    h3: {
      fontFamily: "Open Sans, sans-serif",
      color: "#3f3f3f",
      fontSize: "1.7rem",
    },
    // H4 is used for the VideoTitle component, used exclusively on VideoPlayer.js
    h4: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "2.34rem",
      color: "#3f3f3f",
    },
    // H5 is used for uppercase, spaced out headers like "Meet Karen" on the About Us page
    h5: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      textTransform: "uppercase",
      fontSize: "2rem",
      letterSpacing: "0.25rem",
    },
    // h6 is used for video thumbnail titles on the Video selection page and Flower Titles on the Products page
    h6: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "1.4rem",
      textTransform: "capitalize",
      color: "#3f3f3f",
    },
    // h7 is used for the Footer Title

    h7: {
      fontFamily: "Italiana, sans-serif",
      fontSize: "2.8rem",
      letterSpacing: "1px",
      color: "#0e5352", // primary.main
    },

    // h8 is for "Benefits and Treatments on Service Detail Pages"
    h8: {
      fontSize: "1.6rem",
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      textTransform: "uppercase",
      letterSpacing: "0.2rem",
    },
    // h9 is for Resource titles
    h9: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: "600",
      fontSize: "1.2rem",
      textTransform: "capitalize",
      lineHeight: "normal",
      display: "block",
    },

    body1: {
      fontFamily: "Open Sans, sans-serif",
      color: "#3f3f3f",
      fontSize: "1rem",
      lineHeight: "normal",
    },
    // Pull Quote is used for Video Descriptions on VideoPlayer.js
    pullquote: {
      color: "#3f3f3f",
      fontSize: "1.2rem",
    },
    // Caption is used for the copyright at the bottom of the page, as well as video duration
    caption: {
      textTransform: "capitalize",
      color: "#3f3f3f",
      fontSize: "0.9rem",
    },
    // Tag is used for the trio of adjectives used to categorize and describe videos.
    // Appears in VideoDesc.JS exclusively
    tag: {
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 400,
      fontSize: "1.1rem",
      color: "#ffffff",
    },
    // Used on the Bach Flowers Products page and Subscriptions
    price: {
      fontFamily: "Open Sans, sans-serif",
      color: "#0e5352", // primary.main
      fontWeight: 800,
      fontSize: "2rem",
      textAlign: "center",
    },
  },
  //Buttons
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textAlign: "center",
          fontFamily: "Open Sans, sans-serif",
          transition: "0.4s ease all",
          fontWeight: "500",
          fontSize: "1.1rem",
          lineHeight: "2rem",
        },
      },
      variants: [
        // Navigation Buttons in the Header
        {
          props: { variant: "inactiveNavButton" },
          style: {
            textTransform: "none",
            textAlign: "center",
            color: "text.main",
            fontSize: "1rem",
            lineHeight: "2.6",
            height: "100%",
            borderRadius: "0",
            "&:hover": {
              background: "rgb(135,73,115)",
              background:
                "linear-gradient(0deg, rgba(135,73,115,1) 15%, rgba(255,255,255,0) 15%)",
            },
            "&:hover span": {
              color: "#874973",
            },
          },
        },
        {
          props: { variant: "activeNavButton" },
          style: {
            textTransform: "none",
            textAlign: "center",
            color: "text.main",
            fontSize: "1rem",
            lineHeight: "2.6",
            height: "100%",
            borderRadius: "0",
            background: "rgb(38,121,118)",
            background:
              "linear-gradient(0deg, rgba(38,121,118,1) 15%, rgba(255,255,255,0) 15%)",
            span: {
              color: "#267976",
            },
          },
        },
        {
          props: { variant: "filledGreen" },
          style: {
            borderRadius: 0,
            border: "solid 3px transparent",
            display: "block",
            //borderImageOutset adjusts so that adding the border on hover doesn't change the size
            borderImageOutset: "3px",
            backgroundImage:
              'url("/static/images/buttonBackgrounds/buttonFilledGreen.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "white",
            padding: "10px",
            boxSizing: "border-box",
            width: "100%",
            textTransform: "capitalize",
            "&:hover": {
              // it is impossible to add transition effects to borderimages
              background: "linear-gradient(to right, #0e5352, #2d918d)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "block",
              borderImage: "linear-gradient(to right, #0e5352, #2d918d) 1",
            },
          },
        },
        {
          props: { variant: "filledPurple" },
          style: {
            borderRadius: 0,
            border: "solid 3px transparent",
            display: "block",
            //borderImageOutset adjusts so that adding the border on hover doesn't change the size
            borderImageOutset: "3px",
            backgroundImage:
              'url("/static/images/buttonBackgrounds/buttonFilledPurple.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "white",
            padding: "10px",
            boxSizing: "border-box",
            width: "100%",
            textTransform: "capitalize",
            "&:hover": {
              // it is impossible to add transition effects to borderimages
              background: "linear-gradient(to right, #570e40, #a06b8f)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "block",
              borderImage: "linear-gradient(to right, #570e40, #a06b8f) 1",
            },
            "&:hover svg": {
              color: "#874973", //secondary.main
            },
          },
        },
        {
          props: { variant: "filledBeige" },
          style: {
            borderRadius: 0,
            border: "solid 3px transparent",
            display: "block",
            //borderImageOutset adjusts so that adding the border on hover doesn't change the size
            borderImageOutset: "3px",
            backgroundImage:
              'url("/static/images/buttonBackgrounds/buttonFilledBeige.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "white",
            padding: "10px",
            boxSizing: "border-box",
            width: "100%",
            textTransform: "capitalize",
            "&:hover": {
              // it is impossible to add transition effects to borderimages
              background: "linear-gradient(to right, #b79f8f, #e3c9bc)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              display: "block",
              borderImage: "linear-gradient(to right, #b79f8f, #e3c9bc) 1",
            },
          },
        },
        // Text Links Buttons
        {
          props: { variant: "textLinks" },

          style: {
            borderBottomRightRadius: "10px",
            "&:hover": {
              color: "#874973",
              background: "none",
            },
          },
        },
        // PRODUCTS BUTTONS
        {
          props: { variant: "cartOutsideChange" },
          style: {
            borderRadius: 0,
            display: "block",
            backgroundImage:
              'url("/static/images/buttonBackgrounds/buttonFilledPurple.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "white",
            padding: "14px",
            boxSizing: "border-box",
            width: "100%",
            textTransform: "capitalize",
            overflow: "hidden",
            "& span": {
              opacity: "0",
              display: "inline-block",
              marginRight: "-10px",
              marginLeft: "7px",
              marginTop: "0",
              transition: "0.4s ease all",
              height: "15px",
              width: "15px",
            },
            "& span svg": {
              height: "18px",
              width: "18px",
              top: ".13em",
              position: "relative",
            },
            "&:hover": {
              padding: "14px 14px 14px 9px",
            },
            "&:hover span": {
              opacity: "1",
              marginRight: "-1px",
            },
          },
        },
        {
          props: { variant: "cartOutside+-" },
          style: {
            borderRadius: 0,
            border: "solid 2px #874973", //secondary.main
            display: "flex",
            color: "#874973", //secondary.main
            alignItems: "center",
            justifyContent: "center",
            padding: "5%",
            boxSizing: "border-box",
            width: "100%",
            "& span#initialSpan": {
              display: "inline",
              height: "25px",
              width: "25px",
            },
            "& span#hoverSpan": {
              display: "none",
              height: "25px",
              width: "25px",
            },
            "& span svg": {
              margin: "0 auto",
              height: "25px",
              width: "25px",
            },
            "&:hover": {
              backgroundColor: "#874973", //secondary.main
              color: "white",
            },
            "&:hover span#initialSpan": {
              display: "none",
            },
            "&:hover span#hoverSpan": {
              display: "inline",
            },
          },
        },
        {
          props: { variant: "cartInsideRemove" },
          style: {
            borderRadius: 0,
            border: "none",
            display: "block",
            color: "black",
            padding: "0",
            margin: "0",
            fontWeight: "600",
            fontSize: "1rem",
            boxSizing: "border-box",
            lineHeight: "2rem",
            width: "100%",
            textTransform: "uppercase",
            overflow: "hidden",

            "& span": {
              opacity: "0",
              display: "inline-block",
              marginRight: "-10px",
              marginLeft: "5px",
              marginTop: "0",
              transition: "0.5s ease all",
              height: "15px",
              width: "15px",
            },
            "& span svg": {
              height: "18px",
              width: "18px",
              top: ".13em",
              position: "relative",
            },
            "&:hover": {
              padding: "0 1rem 0 0",
              background: "none",
            },
            "&:hover span": {
              opacity: "1",
              marginRight: "-1px",
            },
          },
        },
        {
          props: { variant: "cartOutside+-" },
          style: {
            borderRadius: 0,
            border: "solid 2px #874973", //secondary.main
            display: "flex",
            color: "#874973", //secondary.main
            alignItems: "center",
            justifyContent: "center",
            padding: "4%",
            boxSizing: "border-box",
            width: "100%",
            maxWidth: "100%",
            "& span#initialSpan": {
              display: "inline",
              height: "25px",
              width: "25px",
            },
            "& span#hoverSpan": {
              display: "none",
              height: "25px",
              width: "25px",
            },
            "& span svg": {
              margin: "0 auto",
              height: "25px",
              width: "25px",
            },
            "&:hover": {
              backgroundColor: "#874973", //secondary.main
              color: "white",
            },
            "&:hover span#initialSpan": {
              display: "none",
            },
            "&:hover span#hoverSpan": {
              display: "inline",
            },
          },
        },
        {
          props: { variant: "cartClose" },
          style: {
            borderRadius: 0,
            border: "none",
            display: "block",
            height: "100%",
            width: "40px",
            float: "right",
            color: "black",
            padding: "0",
            margin: "0",
            fontWeight: "600",
            fontSize: "1.4rem",
            boxSizing: "border-box",
            textTransform: "uppercase",
            overflow: "hidden",

            "&:hover": {
              backgroundImage:
                'url("/static/images/buttonBackgrounds/buttonFilledPurple.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "white",
            },
          },
        },
      ],
    },
  },
  // Miscellaneous
  shape: {
    borderRadius: 0,
  },
  spacing: 12,
});

export default Theme;
