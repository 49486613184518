import React from "react";
import { Button } from "@mui/material";

export default function ButtonOverImg(props) {
    return (<>

<Button variant="filledGreen" href={props.href} sx={{
position: 'absolute',
top: '50%',
left: '50%',
transform: 'translate(-50%, -50%)',
width: '50%',
zIndex: '99'
}}>{props.text}
</Button>

</>)
}