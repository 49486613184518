import React from "react";
import { Typography, Box} from "@mui/material";

export default function VideoTitle(props) {
    return (<>
        <Box
            pt={1}
            px={0}
            mx={0}
        >
                <Typography variant="h4" textAlign="left">
                    {props.text}
                </Typography>
        </Box>
    </>)
}