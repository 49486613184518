import React from "react";
import { Box} from "@mui/material";


export default function FlowerRow() {
    return (
        <>
            <Box
                component="img"
                src="/static/images/FlowerRow.png"
                sx={{  
                width: '100%',
                height: 'auto',
                maxHeight: '100%',
                padding: '0',
            }}
            
            ></Box>
        </>
    )
}